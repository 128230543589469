import ReactDOM from 'react-dom'
import React from 'react'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import App from './App'

ReactDOM.render(<App />, document.getElementById('app'))
