import React from 'react'
import { makeStyles } from '@mui/styles'

import { Loader } from '../../components'

const useStyles = makeStyles({
  container: {
    height: '100vh',
  },
})

const LoadingView = () => {
  const classes = useStyles()

  return (
    <div className={classes.container} data-testid="loader">
      <Loader />
    </div>
  )
}

export default LoadingView
