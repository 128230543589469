import React from 'react'
import { makeStyles } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.success.light,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  },
  successIcon: { fontSize: '3em !important' },
}))

const TaskComplete = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div data-testid="task-completed">
        <Typography variant="h3">Task completed</Typography>
      </div>
      <div>
        <CheckCircleIcon className={classes.successIcon} />
      </div>
    </div>
  )
}

export default TaskComplete
