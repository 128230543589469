import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import {
  Alert,
  AppBar,
  Toolbar,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  TextField,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveIcon from '@mui/icons-material/Remove'
import LogoutIcon from '@mui/icons-material/Logout'

import TaskComplete from './TaskComplete'
import {
  completeCartTask,
  getOrAssignTask,
  updateTaskProductElement,
} from '../../helpers/client'
import LoadingView from './LoadingView'
import ProgressBar from './ProgressBar'

const useStyles = makeStyles((theme) => ({
  productContainerPosition: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
  },
  productDataCard: {
    margin: theme.spacing(2),
  },
  productDataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',

    height: `calc(100vh - 48px - 40px - 175px)`,
    overflowY: 'scroll !important',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  productCountInput: { display: 'flex', alignItems: 'stretch' },
  productInfo: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  productImage: {
    margin: theme.spacing(3),
    alignItems: 'flex-end',

    height: 500,
  },
  productTakingVideo: {
    margin: theme.spacing(3),
    alignItems: 'flex-end',

    height: 500,
  },
  topBarContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const Task = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [task, setTask] = useState(null)
  const [isComplete, setIsComplete] = useState(false)
  const [videoUrlsWatched, setVideoUrlsWatched] = useState(new Set())
  const [isLoading, setIsLoading] = useState(true)
  const [loadError, setLoadError] = useState(null)
  const [productElementIndex, setProductElementIndex] = useState(0)
  const [productCount, setProductCount] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)

  const productElement = useMemo(
    () => (task ? task.product_elements[productElementIndex] : null),
    [productElementIndex, task],
  )
  const allVideosClicked = useMemo(
    () => videoUrlsWatched.size === productElement?.media_urls.length,
    [videoUrlsWatched, productElement],
  )

  // Some videos have errors; in that case, we mark them as
  // already played, so as not to block the agent.
  const onVideoError = useCallback(
    (event) => {
      if (event.target.error?.code === 4) {
        const videoUrl = event.target.src

        setVideoUrlsWatched((arr) => new Set([...arr, videoUrl]))
      }
    },
    [setVideoUrlsWatched],
  )

  async function getTask() {
    setIsLoading(true)
    setLoadError(null)
    try {
      const currTask = await getOrAssignTask()

      setVideoUrlsWatched(new Set())

      if (currTask) {
        setTask(currTask)
        setProductCount(currTask.product_elements[0].product_count)
        setProductElementIndex(0)
      } else {
        setTask(null)
      }
    } catch (err) {
      console.error(err)

      setTask(null)
      setLoadError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTask()
  }, [])

  useEffect(() => {
    setProductCount(productElement?.product_count ?? 0)
  }, [productElement])

  const onChangeProductCount = useCallback(
    (event) => setProductCount(event.target.value),
    [setProductCount],
  )

  const decreaseProductCount = useCallback(() => {
    setProductCount((productCount) =>
      Math.max(0, parseInt(productCount, 10) - 1),
    )
  }, [setProductCount])

  const increaseProductCount = useCallback(() => {
    setProductCount((productCount) => parseInt(productCount || 0, 10) + 1)
  }, [setProductCount])

  const goToPrevious = useCallback(async () => {
    const payload = {
      product_id: productElement.product._id,
      product_count: productCount,
    }

    if (productElement.product_count !== productCount) {
      const taskUpdated = await updateTask(task._id, payload)
      setTask(taskUpdated)
    }

    setProductElementIndex((index) => index - 1)
  }, [
    setProductElementIndex,
    productElement,
    productCount,
    updateTaskProductElement,
    setTask,
  ])

  const goToNext = useCallback(async () => {
    const payload = {
      product_id: productElement.product._id,
      product_count: productCount,
    }

    if (
      productElement.product_count !== productCount ||
      (!productElement.is_completed && allVideosClicked)
    ) {
      const taskUpdated = await updateTask(task._id, payload)

      setTask(taskUpdated)
    }

    setVideoUrlsWatched(new Set())
    setProductElementIndex((index) => index + 1)
  }, [
    setProductElementIndex,
    productElement,
    updateTaskProductElement,
    productCount,
    setTask,
    setVideoUrlsWatched,
    allVideosClicked,
  ])

  const flashSuccessView = async () => {
    setIsComplete(true)

    await new Promise((resolve) => setTimeout(resolve, 750))

    setIsComplete(false)
  }

  const completeTask = useCallback(async () => {
    const payload = {
      product_id: productElement.product._id,
      product_count: productCount,
    }
    await updateTask(task._id, payload)
    await completeCartTask(task._id)
    await flashSuccessView()
    await getTask()
  }, [
    updateTaskProductElement,
    completeCartTask,
    setProductElementIndex,
    flashSuccessView,
    getTask,
  ])

  const updateTask = async (taskId, payload) => {
    setIsUpdating(true)

    const taskUpdated = await updateTaskProductElement(taskId, payload)

    setIsUpdating(false)

    return taskUpdated
  }

  const signoutUser = async () => {
    localStorage.clear()
    navigate('/signin', { replace: true })
  }

  const onPlayVideo = useCallback(
    (event) => {
      const videoUrl = event.target.src

      setVideoUrlsWatched((arr) => new Set([...arr, videoUrl]))
    },
    [setVideoUrlsWatched],
  )

  if (isLoading) {
    return <LoadingView />
  }

  if (isComplete) {
    return <TaskComplete />
  }

  const topBar = (
    <AppBar position="static">
      <Toolbar variant="dense" className={classes.topBarContainer}>
        <Button
          data-testid="sign-out-button"
          variant="contained"
          color="secondary"
          onClick={signoutUser}
          size="small"
          endIcon={<LogoutIcon />}
        >
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  )

  if (loadError) {
    return (
      <>
        {topBar}

        <div data-testid="load-error-alert">
          <Alert severity="error">
            {loadError.response?.body?.message ?? loadError.message}
          </Alert>
        </div>
      </>
    )
  }

  if (!task) {
    return (
      <>
        {topBar}

        <div data-testid="no-task-alert">
          <Alert severity="info">No task is awaiting</Alert>
        </div>
      </>
    )
  }

  return (
    <>
      {topBar}

      <Container disableGutters maxWidth="xxl">
        {productElement ? (
          <div>
            <Card className={classes.productDataCard} variant="outlined">
              <ProgressBar
                currentTaskIndex={productElementIndex}
                totalTasks={task.product_elements.length}
              />

              <div className={classes.productInfo}>
                <div data-testid="cart-id">
                  <Typography component="div" variant="h5">
                    Cart ID: <b>{task.cart_id}</b>
                  </Typography>
                </div>
                <div data-testid="product-name">
                  <Typography component="div" variant="h5">
                    Product: <b>{productElement.product.full_name}</b>
                  </Typography>
                </div>
              </div>

              <Divider />

              <CardContent className={classes.productDataContainer}>
                <img
                  className={classes.productImage}
                  data-testid="product-image"
                  src={productElement.product.product_image_url}
                />

                {productElement.media_urls.map((mediaUrl, index) => (
                  <video
                    key={index}
                    className={classes.productTakingVideo}
                    data-testid="product-movement-video"
                    controls
                    src={mediaUrl}
                    onPlay={onPlayVideo}
                    onError={onVideoError}
                  />
                ))}
              </CardContent>

              <Divider />

              <CardContent className={classes.actionContainer}>
                <LoadingButton
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={goToPrevious}
                  disabled={!productElementIndex || isUpdating}
                  loading={isUpdating}
                  loadingPosition="start"
                >
                  {isUpdating ? 'Saving' : 'Previous'}
                </LoadingButton>

                <div className={classes.productCountInput}>
                  <Button
                    data-testid="decrease-product-count-button"
                    variant="outlined"
                    onClick={decreaseProductCount}
                    disabled={productCount === 0 || isUpdating}
                  >
                    <RemoveIcon />
                  </Button>

                  <TextField
                    data-testid="product-count-input"
                    size="small"
                    label="Product count"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={productCount}
                    onChange={onChangeProductCount}
                    type="number"
                    disabled={isUpdating}
                  />

                  <Button
                    data-testid="increase-product-count-button"
                    variant="outlined"
                    onClick={increaseProductCount}
                    disabled={isUpdating}
                  >
                    <AddIcon />
                  </Button>
                </div>

                {productElementIndex !== task.product_elements.length - 1 ? (
                  <LoadingButton
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={goToNext}
                    disabled={
                      isUpdating ||
                      (!productElement.is_completed && !allVideosClicked)
                    }
                    loading={isUpdating}
                    loadingPosition="end"
                  >
                    {isUpdating ? 'Saving' : 'Next'}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="contained"
                    endIcon={<CheckCircleIcon />}
                    onClick={completeTask}
                    disabled={
                      isUpdating ||
                      (!productElement.is_completed && !allVideosClicked)
                    }
                    loading={isUpdating}
                    loadingPosition="end"
                  >
                    {isUpdating ? 'Saving' : 'Complete'}
                  </LoadingButton>
                )}
              </CardContent>
            </Card>
          </div>
        ) : null}
      </Container>
    </>
  )
}

export default Task
