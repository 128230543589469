import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  LinearProgress,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { authEmail, getMe } from '../../helpers/client'
import { LIGHT_BG_PRIMARY } from '../../constants/color'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: LIGHT_BG_PRIMARY,
  },
  formContainer: {
    width: 400,
    position: 'relative',
  },
  loader: {
    height: 300,
    width: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const SignIn = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [error, setError] = useState(null)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    loadUser()
  }, [])

  async function loadUser() {
    const token = localStorage.getItem('token')

    if (!token) {
      setLoading(false)

      return
    }

    try {
      await getMe(token)
      setLoading(false)
      navigate('/task')
    } catch (err) {
      console.error(err)

      setLoading(false)
      setError(err)
    }
  }

  async function handleSignin({ email, password }) {
    setSubmitting(true)
    setError(null)

    try {
      const { body } = await authEmail(email, password)

      localStorage.setItem('token', body.token)
      localStorage.setItem('roles', JSON.stringify(body.roles))

      setSubmitting(false)
      navigate('/task')
    } catch (error) {
      console.error(error)

      setSubmitting(false)
      setError(error)
    }
  }

  if (loading) {
    return (
      <div className={classes.container}>
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(handleSignin)}>
      <div className={classes.container}>
        <Card className={classes.formContainer}>
          {submitting ? <LinearProgress /> : null}

          <CardContent>
            <h3>Sign In</h3>

            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  margin="dense"
                  size="small"
                  fullWidth
                  disabled={submitting}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  type="password"
                  margin="dense"
                  size="small"
                  fullWidth
                  disabled={submitting}
                />
              )}
            />
          </CardContent>

          <Divider />

          <CardActions>
            <Button
              className={classes.signinButton}
              variant="contained"
              color="primary"
              fullWidth
              disabled={submitting}
              type="submit"
            >
              Sign In
            </Button>
          </CardActions>

          {error ? <Alert severity="error">{error.message}</Alert> : null}
        </Card>
      </div>
    </form>
  )
}

export default SignIn
