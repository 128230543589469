import superagent from 'superagent'

import { API_HOST } from '../config'

const API_VERSION = '2022-11-22'

async function withAuth(cb) {
  try {
    const res = await cb(localStorage.getItem('token'))

    const newToken = res.get('New-Token')
    if (newToken) {
      localStorage.setItem('token', newToken)
    }

    return res
  } catch (err) {
    if (err.status === 401) {
      location = '/signin'
      localStorage.clear()

      return
    }

    if (err.response) {
      const newToken = err.response.get('New-Token')
      if (newToken) {
        localStorage.setItem('token', newToken)
      }
    }

    throw err
  }
}

export async function getMe(token) {
  const res = await superagent
    .get(`${API_HOST}/user`)
    .set('Authorization', `Bearer ${token}`)
    .set('Api-Version', API_VERSION)

  const newToken = res.get('New-Token')
  if (newToken) {
    localStorage.setItem('token', newToken)
  }

  return res
}

export async function authEmail(email, password) {
  const res = await superagent
    .post(`${API_HOST}/auth/email`)
    .set('Api-Version', API_VERSION)
    .send({ email, password })

  const newToken = res.get('New-Token')
  if (newToken) {
    localStorage.setItem('token', newToken)
  }

  return res
}

export async function getOrAssignTask() {
  const { body } = await withAuth((token) =>
    superagent
      .get(`${API_HOST}/cart_confirm_task/`)
      .set('Authorization', `Bearer ${token}`)
      .set('Api-Version', API_VERSION),
  )

  return body
}

export async function updateTaskProductElement(taskId, payload) {
  const { body } = await withAuth((token) =>
    superagent
      .post(`${API_HOST}/cart_confirm_tasks/${taskId}/products`)
      .set('Authorization', `Bearer ${token}`)
      .set('Api-Version', API_VERSION)
      .send(payload),
  )

  return body
}

export async function completeCartTask(taskId) {
  const { body } = await withAuth((token) =>
    superagent
      .post(`${API_HOST}/cart_confirm_tasks/${taskId}/complete`)
      .set('Authorization', `Bearer ${token}`)
      .set('Api-Version', API_VERSION),
  )

  return body
}
