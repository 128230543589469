import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import SignIn from './views/SignIn/SignIn'
import Task from './views/Task/Task'

const theme = createTheme({
  palette: {
    primary: {
      main: '#343285',
    },
    secondary: {
      main: '#e94e6c',
    },
  },
  typography: {
    fontSize: 12,
  },
  spacing: 4,

  // https://mui.com/customization/breakpoints/#custom-breakpoints
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920, // <-- this one was added
    },
  },

  // This overrides the default props of some components
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'none',
          fontSize: '0.85rem',
          fontWeight: 400,
          lineHeight: 1.6,
          letterSpacing: 'inherit',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.85rem',
          fontWeight: 400,
          lineHeight: 1.6,
          letterSpacing: 'inherit',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 12,

          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: { fontSize: 'smaller', fontWeight: 'regular' },
      },
    },
  },

  // This overrides the default style of some components
  overrides: {},
})

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to="/signin" replace />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/task" element={<Task />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
