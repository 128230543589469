import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'

import { LIST_WIDTH, LG_LIST_WIDTH } from '../constants/spacing'

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: theme.spacing(2.5, 3),
  },
  denseSectionContainer: {
    padding: theme.spacing(1.5, 3, 1.5, 3),
  },
  sectionWithBorderTop: {
    borderTop: `1px solid ${grey[400]}`,
  },
  sectionWithBorderBottom: {
    borderBottom: `1px solid ${grey[400]}`,
  },
  narrow: {
    '@media (max-width: 1680px)': {
      maxWidth: LIST_WIDTH,
    },
    '@media (min-width: 1681px)': {
      maxWidth: LG_LIST_WIDTH,
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

const Section = ({
  children,
  className,
  hasBorderBottom,
  hasBorderTop,
  isDense,
  isNarrow,
}) => {
  const classes = useStyles()

  return (
    <section
      className={clsx(
        className,
        classes.sectionContainer,
        isDense ? classes.denseSectionContainer : null,
        hasBorderTop ? classes.sectionWithBorderTop : null,
        hasBorderBottom ? classes.sectionWithBorderBottom : null,
        isNarrow ? classes.narrow : null,
      )}
    >
      {children}
    </section>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  isDense: PropTypes.bool,
  isNarrow: PropTypes.bool,
  children: PropTypes.any.isRequired,
  hasBorderBottom: PropTypes.bool,
  hasBorderTop: PropTypes.bool,
}

Section.defaultProps = {
  isDense: false,
  isNarrow: false,
  hasBorderBottom: false,
  hasBorderTop: false,
}

export default Section
