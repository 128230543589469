import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    width: '100%',
  },
  progressDisplay: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  muiProgressBar: {
    height: '10px !important',
  },
}))

const ProgressBar = ({ currentTaskIndex, totalTasks }) => {
  const classes = useStyles()

  return (
    <div className={classes.progressContainer}>
      <div>
        <LinearProgress
          className={classes.muiProgressBar}
          role="progressbar"
          aria-label="progression of task"
          variant="determinate"
          value={(100 * (currentTaskIndex + 1)) / totalTasks}
        />
      </div>
      <div className={classes.progressDisplay}>
        <Typography>
          {currentTaskIndex + 1} / {totalTasks}
        </Typography>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  currentTaskIndex: PropTypes.number.isRequired,
  totalTasks: PropTypes.number.isRequired,
}

export default ProgressBar
