import React from 'react'
import { makeStyles } from '@mui/styles'

import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const Loader = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  )
}

export default Loader
